* {
  //    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Consolas', 'Courier New', Courier, monospace;
}

h1 {
  font-size: 24;
  text-align: center;
}

section {
  padding: 5% 5% 5% 5%;
}

.homelogo {
  position: absolute;
  vertical-align: top;
  top: 15px;
  left: 15px;
  color: black;

  a {
    color: green;
  }
}

footer {
  position: absolute;
  vertical-align: bottom;
  bottom: 12px;
  right: 12px;
  font-size: 14px;
  font-style: italic;
}

nav {
  position: absolute;
  vertical-align: top;
  top: 12px;
  right: 12px;
}
